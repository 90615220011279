@import '../../styles/mixin';
@import '../../styles/variables';

.primary {
    padding: 10px 30px;
    border-radius: 30px;
    background-color: $soilColor !important;  
    border: none;
    text-transform: uppercase;
    color: $white !important;
    @include font('Roboto', 14px, 600);  
}

.secondary {
    padding: 10px 30px;
    border-radius: 30px;
    background: $lightBlue;
    border: 1px soild $lightSoilColor;
    text-transform: uppercase;
    color: $lightSoilColor !important;
    @include font('Roboto', 14px, 600);
}

button.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
}