$white: #fff;
$black: #000;
$soilColor: #ec5b24;
$lightSoilColor: #d2b48c;
$description: #5d5d5d;
$lightBlue: #f7f8f9;
$pink: #e91e63;
$seperator: #d0cdcd;
$cardBackground: #fdfcf6;
$lightGrey: #778899;
$divider: #eee;
$error: #f44336;