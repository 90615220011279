@import '../../styles/mixin';
@import '../../styles/variables';

.banner {
  p {
    margin: 20px 10px;
    max-width: 1200px;
    min-width: 800px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  button {
    margin-top: 30px;
    display: inline-block;
  }
}

@media(max-width: 700px) {
  .banner {
    p {
      font-size: 25px;
      min-width: 100px;
    }
  }

  .banner-text {
    h2 {
      font-size: 30px;
    }
  }
}
