@import '../../styles/mixin';
@import '../../styles/variables';

header {
  width: 100%;
  display: block;
  position: absolute;
  z-index: 999;

  .wrapper {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .site-brand {
      a {
        display: block;

        img {
          max-width: 200px;
        }
      }
    }
  }
}