body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }
  nav ul li {
    margin-right: 30px; }
    nav ul li:last-child {
      margin: 0; }
    nav ul li a, nav ul li span {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      text-decoration: none;
      cursor: pointer; }
      nav ul li a:hover, nav ul li span:hover {
        color: #ec5b24;
        font-weight: 600;
        text-decoration: none; }
    nav ul li .MuiFormControl-root {
      position: relative;
      min-width: 115px; }
      nav ul li .MuiFormControl-root #drop-down-label {
        position: absolute;
        bottom: 8px; }
      nav ul li .MuiFormControl-root .MuiInputBase-root {
        width: 100%; }
      nav ul li .MuiFormControl-root .MuiSelect-selectMenu {
        text-align: center;
        color: #fff; }
      nav ul li .MuiFormControl-root .MuiSelect-icon {
        color: #fff; }
      nav ul li .MuiFormControl-root .MuiSelect-select:focus {
        background: transparent; }
      nav ul li .MuiFormControl-root .MuiInput-underline:before, nav ul li .MuiFormControl-root .MuiInput-underline::after,
      nav ul li .MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: none; }

.mobile-navigation {
  display: none; }

@media (max-width: 700px) {
  .desktop-navigation ul {
    display: none; }
  .mobile-navigation {
    font-family: "Roboto";
    font-size: 30px;
    font-weight: 600;
    color: #000;
    display: block; } }

header {
  width: 100%;
  display: block;
  position: absolute;
  z-index: 999; }
  header .wrapper {
    padding: 30px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    header .wrapper .site-brand a {
      display: block; }
      header .wrapper .site-brand a img {
        max-width: 200px; }

footer {
  background-color: #d2b48c; }
  footer .copyright-info {
    padding: 20px;
    border-top: 1px solid #eee;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    color: #fff; }
  footer .footer-links {
    padding: 40px 0 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    footer .footer-links .website-logo img {
      width: 300px; }
    footer .footer-links .country-list ul li h3, footer .footer-links .footer-menu ul li h3 {
      font-family: "Roboto";
      font-size: 18px;
      font-weight: 800;
      color: #ec5b24; }
    footer .footer-links .country-list ul li a, footer .footer-links .footer-menu ul li a {
      text-decoration: none;
      padding: 20px 0 0;
      display: inline-block;
      cursor: pointer;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      color: #ec5b24; }
      footer .footer-links .country-list ul li a:hover, footer .footer-links .footer-menu ul li a:hover {
        color: #5d5d5d;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        color: #ec5b24; }

@media (max-width: 700px) {
  .footer-div, .footer-links .website-logo, .footer-links .country-list, footer .footer-links .footer-menu, .footer-links .footer-menu, .footer-links .social-media {
    width: 100%;
    margin: 30px 0;
    background: #d2b48c;
    text-align: center; }
  .footer-links {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

.primary {
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #ec5b24 !important;
  border: none;
  text-transform: uppercase;
  color: #fff !important;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  color: #000; }

.secondary {
  padding: 10px 30px;
  border-radius: 30px;
  background: #f7f8f9;
  border: 1px soild #d2b48c;
  text-transform: uppercase;
  color: #d2b48c !important;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  color: #000; }

button.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed !important;
  pointer-events: auto !important; }

.banner p {
  margin: 20px 10px;
  max-width: 1200px;
  min-width: 800px;
  font-size: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center; }

.banner button {
  margin-top: 30px;
  display: inline-block; }

@media (max-width: 700px) {
  .banner p {
    font-size: 25px;
    min-width: 100px; }
  .banner-text h2 {
    font-size: 30px; } }

.info-card_container {
  text-align: center;
  position: relative; }
  .info-card_container .MuiCardMedia-root {
    height: 200px; }
  .info-card_container .card-footer_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 16px;
    right: 20px; }
  .info-card_container .MuiAvatar-colorDefault {
    background-color: transparent; }
  .info-card_container .tag {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: #d2b48c;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 600;
    color: #fff; }

.tabs_container .tabs-block {
  position: relative; }
  .tabs_container .tabs-block .active-block {
    padding: 20px 0 !important;
    position: absolute;
    top: -4px;
    border-radius: 4px;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
    background-color: #ec5b24;
    transition: all .5s; }
  .tabs_container .tabs-block ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background: #f7f8f9; }
    .tabs_container .tabs-block ul li {
      padding: 10px 0;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      font-family: "Roboto";
      font-size: 13px;
      font-weight: 300;
      color: #000; }
      .tabs_container .tabs-block ul li.active {
        font-family: "Roboto";
        font-size: 13px;
        font-weight: 600;
        color: #fff; }
      .tabs_container .tabs-block ul li span {
        position: relative;
        z-index: 1; }
        .tabs_container .tabs-block ul li span.desktop-steps {
          display: block; }
        .tabs_container .tabs-block ul li span.mobile-steps {
          display: none; }
        @media (max-width: 600px) {
          .tabs_container .tabs-block ul li span.desktop-steps {
            display: none; }
          .tabs_container .tabs-block ul li span.mobile-steps {
            display: block; } }

.tabs_container .tab-body {
  padding: 20px 0; }
  @media (max-width: 600px) {
    .tabs_container .tab-body h2 {
      font-size: 18px;
      line-height: 22px; }
    .tabs_container .tab-body .MuiCardMedia-root, .tabs_container .tab-body .MuiTypography-colorTextSecondary {
      display: none; }
    .tabs_container .tab-body div.MuiCardContent-root {
      max-height: 50px; }
      .tabs_container .tab-body div.MuiCardContent-root .MuiCardHeader-title {
        font-size: 16px; } }

.tabs_container .tab-footer {
  text-align: right; }
  .tabs_container .tab-footer button {
    margin-right: 15px; }

.contact-us-modal {
  max-width: 800px !important;
  width: 90%;
  top: 50%;
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
  margin: auto; }
  .contact-us-modal .modal-body {
    padding: 0;
    overflow: hidden; }
  .contact-us-modal .contact-modal-body {
    position: relative; }
    .contact-us-modal .contact-modal-body .modal-image {
      height: 400px;
      background: url(/static/media/maasai-man.03148416.jpg) center center/cover no-repeat #f7f8f9; }
    .contact-us-modal .contact-modal-body .footer-button {
      position: absolute;
      bottom: 30px;
      right: 30px; }
      .contact-us-modal .contact-modal-body .footer-button button {
        margin-left: 15px; }
    .contact-us-modal .contact-modal-body .contact-us-form {
      padding: 30px 24px 30px; }
      .contact-us-modal .contact-modal-body .contact-us-form .form-title {
        margin-bottom: 24px;
        font-family: "Roboto";
        font-size: 30px;
        font-weight: 600;
        color: #ec5b24; }
      .contact-us-modal .contact-modal-body .contact-us-form .country-list {
        max-height: 180px; }
      .contact-us-modal .contact-modal-body .contact-us-form .discount {
        padding: 10px;
        line-height: 20px;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 600;
        color: #ec5b24; }
      .contact-us-modal .contact-modal-body .contact-us-form form,
      .contact-us-modal .contact-modal-body .contact-us-form .phone-number-input {
        width: 100%; }
      .contact-us-modal .contact-modal-body .contact-us-form .phone-number-input {
        height: 40px; }
      .contact-us-modal .contact-modal-body .contact-us-form .error-text {
        color: #f44336; }
    .contact-us-modal .contact-modal-body .MuiFormControl-root {
      width: 100%; }

@media screen and (max-width: 585px) {
  .modal-image {
    display: none; }
  .form-title {
    margin-top: 1rem; }
  .footer-button {
    position: unset !important;
    text-align: center !important;
    margin: 1rem 0; } }

.itenary-container {
  padding: 30px 60px 0; }
  .itenary-container h3 {
    font-family: "Roboto";
    font-size: 30px;
    font-weight: 600;
    color: #000; }
  .itenary-container h4 {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 600;
    color: #000; }
  .itenary-container .bold-Fonts {
    font-weight: bold; }
  .itenary-container span {
    line-height: 25px; }
  .itenary-container .wrapper > .planned_itinerary {
    margin: 40px 0; }
  .itenary-container .wrapper > .planned_itinerary > .row {
    border: 1px solid #d0cdcd;
    margin: 10px; }
  .itenary-container .itenary-content-wrapper {
    background-color: #fdfcf6;
    border: 0 0 1px 1px solid #d0cdcd; }
    .itenary-container .itenary-content-wrapper hr {
      margin: 0 -16px; }
  .itenary-container .content-padding {
    line-height: 25px;
    padding: 15px 10px; }
    .itenary-container .content-padding .iternary-instructions li {
      padding: 5px 0; }
  .itenary-container .highlighted-content {
    background-color: #d2b48c;
    height: 62px; }
  .itenary-container .itenary-img-wrapper {
    padding: 0; }
    .itenary-container .itenary-img-wrapper img {
      height: auto;
      width: 100%;
      min-height: 430px;
      max-height: 430px; }
  .itenary-container .pricing-container {
    background-color: #fdfcf6;
    padding: 0; }
    .itenary-container .pricing-container .pricing-content {
      border-left: 1px solid #d0cdcd;
      padding: 30px 0;
      min-height: 200px;
      height: calc(100% - 62px); }
      .itenary-container .pricing-container .pricing-content h3 {
        margin-bottom: 10px; }
    .itenary-container .pricing-container .pricing-details_extras {
      padding: 20px 0;
      margin: 20px 0;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      -webkit-align-items: center;
              align-items: center; }
      .itenary-container .pricing-container .pricing-details_extras svg {
        font-family: "Roboto";
        font-size: 40px;
        font-weight: 10;
        color: #ec5b24; }
      .itenary-container .pricing-container .pricing-details_extras span {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 10px;
        padding-left: 10px; }
    .itenary-container .pricing-container .pricing-btn-wrapper {
      height: 62px; }
      .itenary-container .pricing-container .pricing-btn-wrapper button {
        height: 100%;
        width: 100%;
        border-radius: 0; }

.banner-text h2 {
  font-family: "Roboto-LightItalic";
  font-size: 45px;
  font-weight: 300;
  color: #fff;
  font-style: italic; }

.cover-slider_container {
  position: relative;
  background: url(/static/media/nat-geo.1ecfb6e8.jpg) no-repeat;
  background-size: cover; }
  .cover-slider_container h2 {
    font-family: "Roboto";
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .cover-slider_container > div {
    background-image: linear-gradient(-180deg, transparent 0%, #d2b48c 90%); }
  .cover-slider_container h3 {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 15px 0;
    background: #d2b48c;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    color: #fff; }
  .cover-slider_container div[role="menuitem"] {
    position: relative; }
    .cover-slider_container div[role="menuitem"] button {
      width: 86%;
      margin: 0 20px;
      position: absolute;
      bottom: 30px; }

.enquiry-form_container {
  padding: 20px 25px; }
  .enquiry-form_container button {
    width: 100%;
    margin: 20px 0; }

.thank-you-popup .modal-body {
  padding: 40px;
  text-align: center; }
  .thank-you-popup .modal-body svg {
    margin-bottom: 20px;
    border-radius: 50%;
    background: #d2b48c;
    padding: 20px;
    color: white; }
  .thank-you-popup .modal-body h3 {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 200;
    color: #000; }

.action-card--container {
  text-align: center;
  position: relative;
  height: 330px; }
  .action-card--container:hover .action-card--card-media {
    top: -20%; }
  .action-card--container:hover .MuiCardHeader-root, .action-card--container:hover .action-card--description {
    top: -66px; }
  .action-card--container:hover .action-card--description {
    max-height: 300px; }
  .action-card--container .MuiCardMedia-root {
    height: 200px; }
  .action-card--container .MuiCardHeader-root {
    padding-left: 0;
    padding-right: 0; }
  .action-card--container .MuiAvatar-colorDefault {
    background-color: transparent; }

.action-card__header--content.space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.action-card--description {
  max-height: 0;
  overflow: hidden; }

.action-card--card-media,
.MuiCardHeader-root,
.action-card--description {
  position: relative;
  top: 0;
  transition: top .2s ease-out; }

.action-card__header--content__button {
  min-width: 120px !important;
  margin-left: 10px !important; }

.loader-conatiner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh; }

@-webkit-keyframes ldio-6woaeq8kw1k {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes ldio-6woaeq8kw1k {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }
  .loader-conatiner .ldio-6woaeq8kw1k div {
    position: absolute;
    width: 90px;
    height: 90px;
    border: 20px solid #f29d1c;
    border-top-color: transparent;
    border-radius: 50%; }
  .loader-conatiner .ldio-6woaeq8kw1k div {
    -webkit-animation: ldio-6woaeq8kw1k 1s linear infinite;
            animation: ldio-6woaeq8kw1k 1s linear infinite;
    top: 100px;
    left: 100px; }
  .loader-conatiner .loadingio-spinner-rolling-iv1ikupkl3i {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none; }
  .loader-conatiner .ldio-6woaeq8kw1k {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    /* see note above */ }
  .loader-conatiner .ldio-6woaeq8kw1k div {
    box-sizing: content-box; }


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; }

.wrapper {
  width: 85%;
  max-width: 2024px;
  margin: 0 auto; }

button:focus,
a:focus {
  outline: none;
  box-shadow: none; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.text-center {
  text-align: center; }

h2 {
  font-size: 50px;
  font-family: 'Roboto'; }

.modal.fade .modal-dialog {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.flex-box-container {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .flex-box-container img {
    width: 40%; }
  .flex-box-container .description {
    width: 50%; }
    .flex-box-container .description h2 {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 34px; }
    .flex-box-container .description p {
      font-size: 0.875rem;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54); }
  .flex-box-container:nth-child(odd) {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    background-color: #f8f8f8; }
  @media (max-width: 600px) {
    .flex-box-container {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .flex-box-container img {
        width: 100%; }
      .flex-box-container .description {
        width: 100%;
        margin-top: 15px; }
        .flex-box-container .description h2 {
          font-size: 1.6rem; }
      .flex-box-container:nth-child(odd) {
        -webkit-flex-direction: column;
                flex-direction: column; } }

