@import '../../styles/mixin';
@import '../../styles/variables';

nav {
    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
            margin-right: 30px;

            &:last-child {
                margin: 0;
            }

            a,span {
                @include font('Roboto', 16px, 400, $white);
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: $soilColor;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
            .MuiFormControl-root {
              position: relative;
              min-width: 115px;
              #drop-down-label {
                position: absolute;
                bottom: 8px;
              }
              .MuiInputBase-root {
                width: 100%;
              }
              .MuiSelect-selectMenu {
                text-align: center;
                color: $white;
              }
              .MuiSelect-icon {
                color: $white;
              }
              .MuiSelect-select:focus {
                background: transparent;
              }
              .MuiInput-underline:before, .MuiInput-underline::after,
              .MuiInput-underline:hover:not(.Mui-disabled):before {
                border: none;
              }
            }
        }
    }
}

.mobile-navigation {
    display: none;
}

@media (max-width: 700px) {
    .desktop-navigation {
        ul {
            display: none;
        }
    }

    .mobile-navigation {
        @include font('Roboto', 30px, 600);
        display: block;
    }
}
