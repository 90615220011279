body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.wrapper {
  width: 85%;
  max-width: 2024px;
  margin: 0 auto;
}

button,
a {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

h2 {
  font-size: 50px;
  font-family: 'Roboto';
}

.modal {
  &.fade {
    .modal-dialog {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.flex-box-container {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 40%;
  }

  .description {
    width: 50%;

    h2 {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 34px;
    }
  
    p {
      font-size: 0.875rem;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
    background-color: #f8f8f8;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    img {
      width: 100%;
    }

    .description {
      width: 100%;
      margin-top: 15px;
      
      h2 {
        font-size: 1.6rem;
      }
    }

    &:nth-child(odd) {
      flex-direction: column;
    }
  }
}
