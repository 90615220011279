@import '../../styles/mixin';
@import '../../styles/variables';

.contact-us-modal {
  max-width: 800px !important;
  width: 90%;
  top: 50%;
  transform: translateY(-50%) !important;
  margin: auto;

  .modal-body {
    padding: 0;
    overflow: hidden;
  }
  .contact-modal-body {
    position: relative;

    .modal-image {
      height: 400px;
      background: url("../../../assets//images/others/maasai-man.jpg") center center/cover no-repeat $lightBlue;
    }
    .footer-button {
      position: absolute;
      bottom: 30px;
      right: 30px;

      button {
        margin-left: 15px;
      }
    }

    .contact-us-form {
      padding: 30px 24px 30px ;

      .form-title {
        margin-bottom: 24px;
        @include font('Roboto', 30px, 600, $soilColor);
      }

      .country-list {
        max-height: 180px;
      }

      .discount {
        padding: 10px;
        line-height: 20px;
        @include font('Roboto', 12px, 600, $soilColor);
      }

      form,
      .phone-number-input {
        width: 100%;
      }

      .phone-number-input {
        height: 40px;
      }

      .error-text {
        color: #f44336;
      }
    }

    .MuiFormControl-root {
      width: 100%;
    }
  }
}

// *********************************************MEDIA QUERIES*********************************************

@media screen and (max-width: 585px){
  .modal-image {
    display: none;
  }
  .form-title {
    margin-top: 1rem;
  }
  .footer-button {
    position: unset !important;
    text-align: center !important;
    margin: 1rem 0;
  }
}
