.loader-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  @keyframes ldio-6woaeq8kw1k {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-6woaeq8kw1k div {
    position: absolute;
    width: 90px;
    height: 90px;
    border: 20px solid #f29d1c;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-6woaeq8kw1k div {
    animation: ldio-6woaeq8kw1k 1s linear infinite;
    top: 100px;
    left: 100px;
  }
  .loadingio-spinner-rolling-iv1ikupkl3i {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-6woaeq8kw1k {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-6woaeq8kw1k div {
    box-sizing: content-box;
  }
}
