@import '../../styles/mixin';
@import '../../styles/variables';

.thank-you-popup {
  .modal-body {
    padding: 40px;
    text-align: center;

    svg {
      margin-bottom: 20px;
      border-radius: 50%;
      background: $lightSoilColor;
      padding: 20px;
      color: white;
    }

    h3 {
      @include font('Roboto', 18px, 200);
    }
  }
}
