@import '../../styles/mixin';
@import '../../styles/variables';

.info-card_container {
  text-align: center;
  position: relative;

  .MuiCardMedia-root {
    height: 200px;
  }

  .card-footer_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 16px;
    right: 20px;
  }

  .MuiAvatar-colorDefault {
    background-color: transparent;
  }

  .tag {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: $lightSoilColor;

    @include font('Roboto', 14px, 600, $white);
  }
}