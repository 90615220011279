@import '../../styles/mixin';
@import '../../styles/variables';

.tabs_container {
  .tabs-block {
    position: relative;
    .active-block {
      padding: 20px 0 !important;
      position: absolute;
      top: -4px;
      border-radius: 4px;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
      background-color: $soilColor;
      -webkit-transition: all .5s;
      transition: all .5s;
    }
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $lightBlue;

      li {
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        @include font('Roboto', 13px, 300);

        &.active {
          @include font('Roboto', 13px, 600, $white);
        }

        span {
          position: relative;
          z-index: 1;

          &.desktop-steps {
            display: block;
          }

          &.mobile-steps {
            display: none;
          }

          @media (max-width: 600px) {
            &.desktop-steps {
              display: none;
            }
  
            &.mobile-steps {
              display: block;
            }
          }
        }
      }
    }
  }

  .tab-body {
    padding: 20px 0;

    @media (max-width: 600px) {
      h2 {
        font-size: 18px;
        line-height: 22px;
      }

      .MuiCardMedia-root, .MuiTypography-colorTextSecondary {
        display: none;
      }

      div.MuiCardContent-root {
        max-height: 50px;

        .MuiCardHeader-title {
          font-size: 16px;
        }
      }
    }
  }

  .tab-footer {
    text-align: right;
    
    button {
      margin-right: 15px;
    }
  }
}