@import '../../styles/mixin';
@import '../../styles/variables';

.action-card--container {
  text-align: center;
  position: relative;
  height: 330px;
  &:hover{
    .action-card--card-media {
      top: -20%;
    }
    .MuiCardHeader-root, .action-card--description {
      top: -66px
    }
    .action-card--description {
      max-height: 300px;
    }
  }

  .MuiCardMedia-root {
    height: 200px;
  }

  .MuiCardHeader-root {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiAvatar-colorDefault {
    background-color: transparent;
  }
}

.action-card__header--content.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-card--description {
  max-height: 0;
  overflow: hidden;
}

.action-card--card-media, 
.MuiCardHeader-root, 
.action-card--description {
  position: relative;
  top: 0;
  transition: top .2s ease-out;
}

.action-card__header--content__button {
  min-width: 120px !important;
  margin-left: 10px !important;
}