@import '../../styles/mixin';
@import '../../styles/variables';

.cover-slider_container {
  position: relative;
  background: url("../../../assets/images/home/nat-geo.jpg") no-repeat;
  background-size: cover;

  h2 {
    @include font('Roboto', 40px, 500, $white);
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  > div {
    background-image: linear-gradient(-180deg, transparent 0%, $lightSoilColor 90%);
  }

  h3 {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 15px 0;
    background: $lightSoilColor;
    @include font('Roboto', 20px, 400, $white);
  }

  div[role="menuitem"] {
    position: relative;

    button {
      width: 86%;
      margin: 0 20px;
      position: absolute;
      bottom: 30px;
    }
  }
}
