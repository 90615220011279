@import '../../styles/mixin';
@import '../../styles/variables';

.itenary-container {
    padding: 30px 60px 0;
    h3 {
      @include font('Roboto', 30px, 600);
    }
    h4 {
      @include font('Roboto', 20px, 600);
    }

    .bold-Fonts {
      font-weight: bold;
    }
    span {
      line-height: 25px;

    }
    .wrapper > .planned_itinerary {
      margin: 40px 0;
    }
    .wrapper > .planned_itinerary > .row {
      border: 1px solid $seperator;
      margin: 10px;
    }
    .itenary-content-wrapper {
      background-color: $cardBackground;
      border: 0 0 1px 1px solid $seperator;
      hr {
        margin: 0 -16px;;
      }
    }
    .content-padding {
      line-height: 25px;;
      padding: 15px 10px;
      .iternary-instructions {
        li {
          padding: 5px 0;
        }
      }
    }
    .highlighted-content {
      background-color: $lightSoilColor;
      height: 62px;
    }
    .itenary-img-wrapper {
      padding: 0;
      img {
        height: auto;
        width: 100%;
        min-height: 430px;
        max-height: 430px;
      }
    }
    .pricing-container {
      background-color: $cardBackground;
      padding: 0;
      .pricing-content {
        h3 {
          margin-bottom: 10px;
        }
        border-left: 1px solid $seperator;
        padding: 30px 0;
        min-height: 200px;
        height: calc(100% - 62px);
      }
      .pricing-details_extras {
        padding: 20px 0;
        margin: 20px 0;
        justify-content: space-evenly;
        align-items: center;
        // display: flex;

        svg {
          @include font('Roboto', 40px, 10, $soilColor);
        }

        span {
            // margin-right: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 10px;
            padding-left: 10px;
        }

        // @include font('Roboto', 20px, 10, $lightSoilColor);
      }

      .pricing-btn-wrapper {
        height: 62px;
        button {
          height: 100%;
          width: 100%;
          border-radius: 0;
        }
      }
    }
}
